import React, { Component } from 'react';

class Deleted extends Component {
  render() {
    return (
      <div id="account">
        <h2>Tili poistettu</h2>
        <p>Tilisi on nyt poistettu ja sinut on kirjattu pois palvelusta.</p>
      </div>
    );
  }
}

export default Deleted;
